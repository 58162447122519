/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-08-31",
    versionChannel: "nightly",
    buildDate: "2023-08-31T00:23:25.735Z",
    commitHash: "cf9171cb787424233d4b78bdfa7e8b0ea0b7957e",
};
